import { Vue, Inject } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
//import LoginForm from '@/account/login-form/login-form.vue';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import axios, { AxiosPromise } from 'axios';
import TranslationService from '@/locale/translation.service';
import { VERSION } from '@/constants';
import '@/shared/config/dayjs';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,
    //'login-form': LoginForm,

    'jhi-footer': JhiFooter,
  },
  methods: {
    globalInitProfile() {
      this.$data.user = this.$store.getters.account;
      // console.log('user::', this.$data.user);
      /* if (this.$data.user.imageUrl != null || this.$data.user.imageUrl != '' ) {
        this.$data.avatarUrl = '/api/files/user/' + this.$data.user.id + '/avatar.jpg';
        this.$data.rand = Date.now();
        console.log('i am not null? avatar url =' + this.$data.avatarUrl + ' and Rand:' + this.$data.rand);
      }
      //return this.$data.user; */
    },
  },
})
export default class App extends Vue {
  name: 'App';

  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;

  @Inject('accountService') private accountService: () => AccountService;
  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  public subIsActive(input) {
    console.log('Input met:', input);
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): AxiosPromise<any> {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    // clear localStorage
    localStorage.clear();
    sessionStorage.clear();
    console.log('Logout?');
    this.$store.commit('logout');
    //return Promise.resolve(this.$router.currentRoute);
    return axios.post('api/logout');
  }

  public openLogin(): void {
    this.loginService().login((<any>this).$root);
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (this.hasAnyAuthorityValues[authorities] !== value) {
          this.hasAnyAuthorityValues = { ...this.hasAnyAuthorityValues, [authorities]: value };
        }
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }
}
