import { Authority } from '@/shared/security/authority';
const FullLayout = () => import('@/layouts/full-layout/Layout.vue');

export default [
  {
    path: '/init-setup',
    component: FullLayout,
    children: [
      {
        path: '1st-step-proceed',
        name: '1st-Step-Proceed',
        component: () => import('@/views/init-setup/InitSetup.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'init-user-profile',
        name: 'Init-User-Profile',
        component: () => import('@/views/init-setup/components/FormUserProfile.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'init-business-profile',
        name: 'Init-Business-Profile',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/init-setup/components/FormBusinessProfile.vue'),
      },
    ],
  },
];
