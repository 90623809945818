import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/book-keeping',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'cash-book',
        name: 'Cash-Book',
        component: () => import('@/views/book-keeping/cash-book/CashBook.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Transaction-New',
        path: 'cash-book/transaction/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/cash-book/components/FormEditNewTransaction.vue'),
      },
      {
        name: 'Transaction-Edit',
        path: 'cash-book/transaction/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/cash-book/components/FormEditNewTransaction.vue'),
      },
      {
        name: 'BankCash-Transfer-Transaction-New',
        path: 'cash-book/bankcashtransfer/transaction/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/cash-book/components/FormAddNewBankCashTransferTransaction.vue'),
      },
      {
        path: 'ledger-book',
        name: 'Ledger-Book',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/ledger-book/LedgerBook.vue'),
      },
      {
        path: 'view-ledger-book',
        name: 'View-Ledger-Book',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/ledger-book/components/ViewLedgerBook.vue'),
      },
      {
        name: 'Set-Ledger-Type',
        path: 'ledger-book/transaction/set/ledger/type',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/ledger-book/components/SetLedgerType.vue'),
      },
      {
        path: 'journal-entry',
        name: 'Journal Entry',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/journal-entry/JournalEntry.vue'),
      },
      {
        name: 'Journal-BankCash-Transfer-Transaction-New',
        path: 'journal-entry/transfer/transaction/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/journal-entry/components/FormAddBankCashTransferTransaction.vue'),
      },
      {
        name: 'Journal-Add-Transaction-New',
        path: 'journal-entry/transaction/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/journal-entry/components/FormAddNewJournalTransaction.vue'),
      },
    ],
  },
  {
    name: 'Book-Keeping-Print-Main',
    component: () => import('@/layouts/print-layout/Printlayout.vue'),
    path: '/',
    children: [
      {
        path: '/book-keeping/cash-book/transaction/print',
        name: 'Transaction-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/book-keeping/cash-book/components/PrintTransaction.vue'),
      },
    ],
  },
];
